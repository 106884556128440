<template>
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.9941 2.09998H9.01696C4.6832 2.09998 2.09961 4.68228 2.09961 9.01388V18.9742C2.09961 23.3177 4.6832 25.9 9.01696 25.9H18.9822C23.316 25.9 25.8996 23.3177 25.8996 18.9861V9.01388C25.9115 4.68228 23.3279 2.09998 18.9941 2.09998ZM11.5529 15.5113C10.7433 16.7251 9.62416 17.7128 8.31451 18.3673C8.19545 18.4268 8.05258 18.4625 7.92161 18.4625C7.58824 18.4625 7.27869 18.284 7.12391 17.9746C6.8977 17.5343 7.07629 16.9988 7.52871 16.7727C8.56453 16.261 9.44557 15.4756 10.0766 14.5236C10.2909 14.2023 10.2909 13.7977 10.0766 13.4764C9.43366 12.5244 8.55262 11.739 7.52871 11.2273C7.07629 11.0131 6.8977 10.4776 7.12391 10.0254C7.33822 9.58508 7.87399 9.40658 8.31451 9.63268C9.62416 10.2872 10.7433 11.2749 11.5529 12.4887C12.172 13.405 12.172 14.595 11.5529 15.5113ZM19.9585 18.4625H15.1961C14.708 18.4625 14.3032 18.0579 14.3032 17.57C14.3032 17.0821 14.708 16.6775 15.1961 16.6775H19.9585C20.4467 16.6775 20.8515 17.0821 20.8515 17.57C20.8515 18.0579 20.4467 18.4625 19.9585 18.4625Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ConsoleIcon',
    };
</script>
