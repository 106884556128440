<template>
    <scroll-container type="horizontal">
        <pre ref="pre" class="hljs" v-html="highlightedCode" />
    </scroll-container>
</template>

<script>
    import hljs from 'highlight.js';
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'CodeSnippet',
        components: {
            ScrollContainer,
        },
        props: {
            code: {
                type: String,
                required: true,
            },
            language: {
                type: String,
                required: true,
            },
        },
        computed: {
            highlightedCode() {
                return hljs.highlight(this.code, { language: this.language }).value;
            },
        },
    };
</script>

<style lang="scss" scoped>
    pre {
        display: inline-block;
        min-width: 100%;
        padding: 1.5rem;
        border-radius: 0.6rem;
        background: #131825;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;

        ::v-deep {
            .hljs-attr {
                color: #ff6060;
            }

            .hljs-keyword,
            .hljs-number {
                color: #5988ff;
            }

            .hljs-punctuation {
                color: #d0d5e8;
            }

            .hljs-string {
                color: #5bdbac;
            }
        }
    }

    @media screen and (min-width: 120em) {
        pre {
            font-size: 1.4rem;
        }
    }
</style>
